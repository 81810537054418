/* base.scss */
html, body {
    height: 100%;
}

body {
    background: $black;
}

/* Typography */
h4,
h5 {
    display: inline-block;
    background: $gray-800;
    color: $white;
    padding: 0.40rem 0.5rem;
}

strong {
    font-family: 'RobotoMono-Bold', 'Roboto-Bold';
    font-weight: 900;
}

a {
    color: $gray-600;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'RobotoMono-Regular', 'Roboto';

    &:hover {
        color: $white;
        text-decoration: none;
    }
}

p {
    margin-bottom: 2rem;
}

hr {
    border: 4px solid $gray-800;
    margin: 3rem 0;
}

img.invert {
    filter: invert(1);
}

pre,
blockquote {
    font-family: 'RobotoMono Mono', 'Roboto';
    color: $gray-600;
    border-left: 1px dotted $gray-800;
    padding-left: 1rem;

    a {
        color: $white;
    }
}

/* Main Navbar */
header.navbar {
    border: 0;
    box-shadow: 0 0 0;
    height: 100px;
    background: $black;
    margin: 0 0 1rem 0;
    padding: 0 1rem;

    div.container {
        border-top: 1px solid $gray-800;
        border-bottom: 1px solid $gray-800;
    }

    button.navbar-toggler {
        border-radius: 0px;
        background-color: transparent;

        span.navbar-toggler-icon {
           color: $white;
        }
    }

    a.navbar-title {
        font-family: 'RobotoMono-Bold';
        color: $white;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
    }

    li.nav-item {
        margin-left: 1.5rem;

        img {
            filter: invert(1);
            height: 24px;
        }

        div.dropdown-menu {
            right: 0px;
            padding: 0;

            a.dropdown-item {
                padding: .75rem 1rem;

                &:hover {
                    background: $gray-300;
                    color: $black;
                }
            }
        }
    }

    button.navbar-toggler {
        border: 1px solid $white;

        span.navbar-toggler-icon {
            background: transparent url($icon-navbar-toggler-white) no-repeat;
            background-size: contain;
        }
    }

    nav.navbar-collapse {
        background: transparent;
        z-index: 10000;

        &.show {
            background: $white;

            a.nav-link {
                color: $black;
                &:hover {
                    color: $gray-600 !important;
                }

                img {
                    filter: invert(0);
                }
            }
        }

        a.nav-link {
            color: $gray-600;

            &:hover {
                color: $white !important;
            }
        }
    }
}


ul.groups {
    div.group-image {
        @include make-col-ready;
        @include media-breakpoint-up(xs) {
            @include make-col(12);
        }
        @include media-breakpoint-up(sm) {
            @include make-col(12);
        }
        @include media-breakpoint-up(md) {
            @include make-col(4);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(3);
        }

        background-color: $black;
        border: 1px solid $gray-800;
        box-shadow: 8px 8px $gray-800;
        padding: 1rem auto;
        margin-bottom: 2.5rem;
        text-align: center;

        img {
            max-height: 100px;
        }
    }
}

main.updates {
    ul {
        time {
            display: block;
            color: $gray-700;
            padding: .5rem 0;
            font-family: 'RobotoMono-Regular';
        }
    }
}

article {
    padding: 1rem 0rem;

    time {
        display: block;
        border-top: 3px solid $gray-800;
        border-bottom: 3px solid $gray-800;
        color: $gray-700;
        padding: .5rem 0;
        margin-bottom: 1.5rem;
        font-family: 'RobotoMono-Regular';
    }

    main {
        padding: 0;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0 0 2rem 0;

            li {
                margin: .75rem 0;
            }

            li::before {
                content: "\25A0";
                color: $gray-800;
                display: inline-block;
                width: 1em;
                margin-left: 0;
                margin-right: 1rem;
            }
        }

        ol {
            list-style: none;
            counter-reset: li;
            padding: 0;
            margin: 0 0 2rem 0;
        }

        ol li::before {
            content: '0' counter(li);
            color: $gray-700;
            font-family: 'RobotoMono-Bold';
            display: inline-block;
            width: 2em;
            margin-left: 0em
        }

        ol li {
            counter-increment: li
        }
    }
}

div.footer-inner {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem .4rem;
    border: 1px solid $white;
    color: $white;
    background-color: $black;

    div.row {
        padding: 0;
        margin: 0;
    }

    a.link-logo,
    a.link-purpose {
        display: inline-block;

        img {
            height: 32px;
        }
    }

    div.ticker-container {
        a.ticker-link {
            color: $white !important;
            line-height: 18px;

            &:hover {
                color: $gray-800;
            }
        }
    }
}

