/* Extends EOTL Hugo Theme */

/* Categories */
div.category {
    @include make-col-ready;
    @include media-breakpoint-up(xs) {
        @include make-col(12);
    }
    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    @include media-breakpoint-up(md) {
        @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(3);
    }

    height: 200px;
    border: 1px solid $white;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        background-color: $black !important;
        color: $white;

        img {
            margin-top: 1.75rem;
            filter: invert(1);
        }

        h2 {
            position: absolute;
            left: 15px;
            bottom: 0px;
            font-size: 21px;
        }

        &:hover {
            background-color: $white !important;
            color: $black !important;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;

            img {
                filter: invert(0);
            }

            h2 {
                color: $black !important;
            }
        }
    }
}


/* Items */
li.item {
    display: block;

    img.item-image {
        width: 200px;
        height: auto;
        display: block;
        position: relative;
        font-family: 'Roboto-Regular';
        font-weight: 300;
        line-height: 2;
        text-align: center;
        color: $gray-700;

        &:before {
            display: block;
            padding-top: 2.5rem;
        }

        &:after {
            content: "No image available";
            display: block;
            font-size: 12px;
            padding-bottom: 2.75rem;
            color: $gray-900;
        }
    }

    div.choose-size {

        img {
            filter: invert(1);
            height: 36px;
        }

        input[type=number] {
            border: 1px solid $white;
            background: $black;
            color: $white;
            padding: .25rem .75rem;
            width: 85px;
        }

        input[type=checkbox] + label {
            color: #ccc;
            font-style: italic;
        }

        div.form-check {
            &:hover {
                background: $gray-700;
                color: $white;

                label {
                    background: $gray-700;
                }

                img {
                    filter: invert(1);
                }
            }
        }

        input[type="radio"]:checked {
            background: $white;
            color: $black;
        }
    }

    div.item-estimated-price {
        background-color: #333333;
        color: #000000;
        margin-bottom: 1rem;
        padding: .5rem 1rem;
        text-align: center;
        font-size: .75rem;

        span {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    table.details {
        width: 100%;
        border: 1px solid $gray-800;

        thead {
            tr {
                td {
                    border-bottom: 1px solid $gray-900;
                    font-size: .7rem;
                    color: $gray-600;
                }
            }
        }

        tr {
            td {
                padding: .25rem;
                border-right: 1px solid $gray-800;
                color: $gray-300;
            }
        }
    }
}



div.item-checkout {
    border-bottom: 1px solid $gray-800;

    div.icon {
        @include make-col-ready;
        @include media-breakpoint-up(sm) {
            @include make-col(6);
        }
        @include media-breakpoint-up(md) {
            @include make-col(2);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(2);
        }

        text-align: left;

        img {
            height: 36px;
        }
    }

    div.item {
        @include make-col-ready;
        @include media-breakpoint-up(sm) {
            @include make-col(6);
        }
        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(6);
        }

        text-align: left;
    }

    div.pricing {
        @include make-col-ready;
        @include media-breakpoint-up(sm) {
            @include make-col(12);
        }
        @include media-breakpoint-up(md) {
            @include make-col(4);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }

        text-align: right;
    }

    img.item-image {
        width: 200px;
        height: auto;
        line-height: 2;
        color: $gray-700;
    }

}
