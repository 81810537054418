$container-max-widths: (sm: 600px, md: 800px, lg: 900px, xl: 1000px) !default;

// Colors
$white:    #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e6e6e6 !default;
$gray-300: #cccccc !default;
$gray-400: #bbbbbb !default;
$gray-500: #adb5bd !default;
$gray-600: #777777 !default;
$gray-700: #444444 !default;
$gray-800: #212121 !default;
$gray-900: #111111 !default;
$black:    #000000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #9B479F !default;
$pink:    #e83e8c !default;
$red:     #D9230F !default;
$orange:  #F98E05 !default;
$yellow:  #ffc107 !default;
$green:   #469408 !default;
$teal:    #20c997 !default;
$cyan:    #029ACF !default;

$primary:       $white !default;
$secondary:     $gray-500 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-300 !default;
$dark:          $gray-800 !default;

// Spacing & Style
$border-radius: 0px;

// Body
$body-bg: $black !default;
$body-color: $white;

$font-stack: 'Roboto-Regular', sans-serif;
$regular: 'Robot-Regular', sans-serif;
$medium: 'Roboto-Medium', sans-serif;
$bold: 'Roboto-Bold', sans-serif;

$font-family-sans-serif: $font-stack;
$font-size-base: 1rem !default;

// Dropdowns
$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $primary !default;

// Navs
$nav-link-padding-y:                .9rem !default;
$nav-link-disabled-color:           $gray-400 !default;
$nav-tabs-border-color:             darken(#fff, 6.5%) !default;

// Navbar
$navbar-dark-color:                 rgba($white,.75) !default;
$navbar-dark-hover-color:           $white !default;

// Pagination
$pagination-border-color:           $nav-tabs-border-color !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $primary !default;
$pagination-hover-border-color:     $primary !default;

$pagination-disabled-color:         $gray-400 !default;
$pagination-disabled-border-color:  $pagination-border-color !default;

// Jumbotron
$jumbotron-bg:                      $nav-tabs-border-color !default;

// Cards
$card-border-color:                 $nav-tabs-border-color !default;

// Popovers
$popover-border-color:              $nav-tabs-border-color !default;

// Modals
$modal-content-border-color:        $nav-tabs-border-color !default;
$modal-header-border-color:         $nav-tabs-border-color !default;

// Progress bars
$progress-bar-color:                $primary !default;

// List group
$list-group-border-color:           $nav-tabs-border-color !default;
$list-group-disabled-bg:            $nav-tabs-border-color !default;

// Breadcrumbs
$breadcrumb-bg:                     $white !default;
