// Bootswatch

// Variables ===================================================================
@mixin btn-outline($color) {
  filter: none;
  border: 2px solid $color;
  border-radius: 0px;
  color: $color;
  background-color: transparent;
}

@mixin btn-outline-hover($color, $color-text) {
  filter: none;
  border: 2px solid $color;
  border-radius: 0px;
  color: $color-text;
  background-color: $color;
}

h1, h2, h3 {
    font-family: 'RobotoMono-Bold';
    text-transform: uppercase;
    margin-bottom: 1.75rem;
}

h4, h5, h6 {
    font-family: 'Roboto-Bold';
}

i, em {
    color: #777777;
}


// Navbar ======================================================================
.navbar {
  border: 0px;

  &-fixed-top {
    border: 0 0 0px 0;
  }

  &-fixed-bottom {
    border: 0px;
  }
}


// Backgrounds =================================================================
.bg-primary {
  background-color: $white !important;
  border-color: darken($primary, 6.5%) !important;
}

.bg-dark {
  background-color: $black !important;
  border-color: darken($dark, 6.5%) !important;
}

.bg-light {
  background-color: $gray-300 !important;
  border-color: darken(#fff, 6.5%);
}


// Buttons =====================================================================
.btn-primary {
  @include btn-outline($primary);
}

.btn-primary:hover {
  @include btn-outline-hover($primary, $black);
}

.btn-secondary {
  @include btn-outline($secondary);
}

.btn-secondary:hover {
  @include btn-outline-hover($secondary, $black);
}

.btn-secondary:focus,
.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 0 0.2rem rgba($gray-200, 0.5);
}

.btn-success {
  @include btn-outline($success);
}

.btn-success:hover {
  @include btn-outline-hover($success, $white);
}

.btn-info {
  @include btn-outline($info);
}

.btn-info:hover {
  @include btn-outline-hover($info, $white);
}

.btn-warning {
  @include btn-outline($warning);
}

.btn-warning:hover {
  @include btn-outline-hover($warning, $white);
}

.btn-danger {
  @include btn-outline($danger);
}

.btn-danger:hover {
  @include btn-outline-hover($danger, $white);
}


.btn-dark {
  @include btn-outline($dark);
}

.btn-dark:hover {
  @include btn-outline-hover($dark, $white);
}

.btn-light {
  @include btn-outline($light);
}

.btn-light:hover {
  @include btn-outline-hover($light, $black);
}


// Forms =======================================================================
form {
    background: $white;
    color: $black;
}


legend,
label {
    background: $black;
    color: $white;
    padding: .25rem 1rem;
    margin: 0 0;
}


// Navs =======================================================================
.breadcrumb {
  border: 1px solid darken(#fff, 6.5%);
}

.pagination {
  .page-link:hover {
    text-decoration: none;
  }
}

