/* Roboto */
@font-face {
  font-family: 'Roboto-Thin';
  src: url('../../fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../../fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../../fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../../fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../../fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../../fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../../fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../../fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* RobotoMono */
@font-face {
  font-family: 'RobotoMono-Thin';
  src: url('../../fonts/RobotoMono-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono-ThinItalic';
  src: url('../../fonts/RobotoMono-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'RobotoMono-Light';
  src: url('../../fonts/RobotoMono-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono-LightItalic';
  src: url('../../fonts/RobotoMono-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'RobotoMono-Regular';
  src: url('../../fonts/RobotoMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono-Italic';
  src: url('../../fonts/RobotoMono-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'RobotoMono-Medium';
  src: url('../../fonts/RobotoMono-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono-Bold';
  src: url('../../fonts/RobotoMono-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

